
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    isRequestingUpdate: {
      required: true,
      type: Boolean
    },
    isUpdating: {
      required: true,
      type: Boolean
    }
  },
  setup (props, { emit }) {
    function emitStartUpdating () {
      emit('start-updating');
    }

    function emitStopUpdating () {
      emit('stop-updating');
    }

    function emitDoUpdate () {
      emit('do-update');
    }

    return {
      props,
      emitStartUpdating,
      emitStopUpdating,
      emitDoUpdate
    };
  }
});
