import { ValidationModel } from '@/validation';
import { useStore as useValidationStore } from '@/store/validation';
import { computed, ComputedRef, Ref } from 'vue';
import { IValidationStore } from '@/store/contracts/validation';
import { impl } from '@/utils/impl';

export function useFieldValidation<F extends string, M> (validationModel: ValidationModel<M>, showErrors: Ref<boolean> | ComputedRef<boolean>): {
  validationStore: IValidationStore,
  fieldIsError: (fieldName: F) => boolean,
  fieldErrorMessage: (fieldName: F) => string
} {
  const validationStore = useValidationStore();

  return {
    validationStore,

    fieldIsError: (field: F) => {
      return showErrors.value && validationStore.fieldIsError(validationModel.modelName, field);
    },

    fieldErrorMessage: (field: F) => {
      return validationStore.fieldErrorMessage(validationModel.modelName, field);
    }
  };
}

export interface IFieldValidationRefs {
  fieldIsError: boolean,
  fieldErrorMessage: string
}

export function useFieldsValidation<F extends string, M> (validationModel: ValidationModel<M>, showErrors: Ref<boolean> | ComputedRef<boolean>, fields: F[]): {
  validationStore: IValidationStore,
  validationRefs: ComputedRef<IIndexable<IFieldValidationRefs>>
} {
  const { validationStore, fieldIsError, fieldErrorMessage } = useFieldValidation(validationModel, showErrors);
  return {
    validationStore,
    validationRefs: computed(() => fields.reduce((acc, field) => {
      acc[field] = {
        fieldIsError: fieldIsError(field),
        fieldErrorMessage: fieldErrorMessage(field)
      };
      return acc;
    }, impl<IIndexable<IFieldValidationRefs>>({})))
  };
}
