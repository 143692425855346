import { render } from "./AsyncContent.vue?vue&type=template&id=2ba0804d"
import script from "./AsyncContent.vue?vue&type=script&lang=ts"
export * from "./AsyncContent.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QPageContainer,QSpinner});
