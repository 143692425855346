<template>
    <q-page-container>
        <slot name="before"/>
        <router-view v-slot="{ Component }">
            <suspense>
                <template #default>
                    <component :is="Component" />
                </template>
                <template #fallback>
                    <div class="o-async-content__loading">
                        <q-spinner size="lg"/>
                    </div>
                </template>
            </suspense>
        </router-view>
        <slot name="after"/>
    </q-page-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
