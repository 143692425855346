import { computed, ComputedRef, ref, Ref } from 'vue';

export interface IInlineEditableComp {
  editText: Ref<string | null>;
  compText: ComputedRef<string | null>;
  isEditing: Ref<boolean>;
  isRequestingUpdate: Ref<boolean>;
  doUpdate: () => Promise<void>;
  stopEditing: () => Promise<void>;
  startEditing: () => Promise<void>;
}

export function useInlineEditable (
  baseValueGetter: () => string | null
  , reactiveValue: Ref<string | null> | ComputedRef<string | null>
  , inputEl?: Ref<HTMLInputElement | HTMLTextAreaElement | null> | ComputedRef<HTMLInputElement | HTMLTextAreaElement | null>
  , onDoUpdate?: (comp: Omit<IInlineEditableComp, 'doUpdate'>) => Promise<void>
  , onStartEditing?: () => Promise<void>
  , onStopEditing?: () => Promise<void>): IInlineEditableComp {
  const isEditing = ref(false);
  const isRequestingUpdate = ref(false);
  const editText = ref(baseValueGetter());
  const compText = computed({
    get: () => isEditing.value ? editText.value : reactiveValue.value,
    set: (val) => { editText.value = val; }
  });

  const startEditing = async () => {
    isEditing.value = true;
    if (inputEl?.value) {
      inputEl.value.focus();
      inputEl.value.select();
    }
    if (onStartEditing) {
      await onStartEditing();
    }
  };

  const stopEditing = async () => {
    isEditing.value = false;
    isRequestingUpdate.value = false;
    editText.value = baseValueGetter();
    const selection = window.getSelection();
    if (selection) {
      selection.removeAllRanges();
    }
    if (onStopEditing) {
      await onStopEditing();
    }
  };

  const doUpdate = async () => {
    if (isEditing.value) {
      isRequestingUpdate.value = true;
      if (onDoUpdate) {
        await onDoUpdate({
          editText,
          compText,
          isEditing,
          isRequestingUpdate,
          stopEditing,
          startEditing
        });
      } else {
        await stopEditing();
      }
      isRequestingUpdate.value = false;
    }
  };

  return {
    editText,
    compText,
    isEditing,
    isRequestingUpdate,
    doUpdate,
    stopEditing,
    startEditing
  };
}
