<template>
  <div class="o-edit-field-actions">
    <template v-if="props.isUpdating">
      <q-btn
        class="o-edit-field-actions__action -cancel"
        size="sm"
        icon="mdi-close"
        round
        dense
        color="pl-navy-900"
        flat
        @click="emitStopUpdating"
      />
      <q-btn
        class="o-edit-field-actions__action -save"
        size="sm"
        icon="mdi-content-save-outline"
        :loading="props.isRequestingUpdate"
        round
        dense
        color="pl-navy-900"
        flat
        @click="emitDoUpdate"
      />
    </template>
    <template v-else>
      <q-btn
        class="o-edit-field-actions__action -update"
        size="sm"
        icon="mdi-pencil-outline"
        round
        dense
        flat
        color="pl-navy-900"
        @click="emitStartUpdating"
      />
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    isRequestingUpdate: {
      required: true,
      type: Boolean
    },
    isUpdating: {
      required: true,
      type: Boolean
    }
  },
  setup (props, { emit }) {
    function emitStartUpdating () {
      emit('start-updating');
    }

    function emitStopUpdating () {
      emit('stop-updating');
    }

    function emitDoUpdate () {
      emit('do-update');
    }

    return {
      props,
      emitStartUpdating,
      emitStopUpdating,
      emitDoUpdate
    };
  }
});
</script>
