import Axios, { AxiosRequestConfig } from 'axios';
import { ApiResponse, ResponseStatus } from '@/store/utils/apiResponseHandling';
export async function sendRequest<T> (
  url: string,
  method: 'get' | 'post' | 'delete',
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data?: any,
  requestModifier?: (config: AxiosRequestConfig) => Promise<AxiosRequestConfig>
): Promise<ApiResponse<T>> {
  try {
    let config: AxiosRequestConfig;
    if (method === 'get') {
      config = {
        url,
        method,
        withCredentials: true,
        params: data
      };
    } else {
      config = {
        url,
        method,
        withCredentials: true,
        data
      };
    }
    if (requestModifier) {
      config = await requestModifier(config);
    }
    const result = await Axios.request<T>(config);
    return [result.data, result];
  } catch (e) {
    if (e.response && e.response.status === 401) {
      return [ResponseStatus.NotAuthenticated, e.response];
    } else if (e.response && e.response.status === 404) {
      return [ResponseStatus.NotFound, e.response];
    }
    return [ResponseStatus.Error, e.response ?? null];
  }
}
